import { Dropdown, Icon, Menu, notification } from 'antd';
import clsx from 'clsx';
import { ComponentProps, PureComponent } from 'react';
import styled from 'styled-components';
import { LAST_REVIEW_SEEN_COOKIE_NAME } from '../../config';
import { HELP_CENTER_URL } from '../../constants/url';
import { analyticsTrackEvent } from '../../core/analytics';
import {
  TOP_NAV_SELECT_CALL_CENTER,
  TOP_NAV_SELECT_INBOX,
  TOP_NAV_SELECT_LOG_OUT,
  TOP_NAV_SELECT_MESSAGING,
  TOP_NAV_SELECT_PAYMENTS,
  TOP_NAV_SELECT_QUEUE,
  TOP_NAV_SELECT_REPORTS,
  TOP_NAV_SELECT_RESPONSIVE_REPORTS_LIVE_STATS,
  TOP_NAV_SELECT_REVIEWS,
  TOP_NAV_SELECT_REVIEWS_MODERATION,
  TOP_NAV_SELECT_SETTINGS,
  TOP_NAV_SELECT_USERS,
} from '../../core/analytics/events';
import { getTrackingProperties } from '../../core/analytics/properties';
import { getBooleanFlag, useBooleanFlag } from '../../core/flags/flags';
import history from '../../core/history';
import { isSolvEmployeeAccount } from '../../core/session';
import { getAccountHolderName } from '../../core/util/clinicAccounts';
import { removeCookie } from '../../core/util/cookie';
import { isTestLocation } from '../../core/util/location';
import { getQueueUrlPath, getTelemedOnlyReportsRedirect } from '../../core/util/url';
import { shouldShowFeature } from '../../core/viewCustomization';
import { FeatureName } from '../../core/viewCustomization/featureNames';
import { RemixRoutes } from '../../routes/remixRoutes';
import { AccountLogin } from '../../types/RootState';
import { breakpoints } from '../primitives/constants';
import { useUiConfig } from '../UiConfigContext';
import withViewport from '../withViewport';
import { InboxOnboarding } from '../Inbox/InboxOnboarding';

const MenuItem = Menu.Item;

const ResponsiveMenuItem = styled(MenuItem)`
  display: none;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    display: block;
  }
`;

const DesktopMenuItemBase = styled(MenuItem)`
  display: block;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    display: none !important;
  }
`;

const DesktopMenuItem = (props: ComponentProps<typeof MenuItem>) => (
  <DesktopMenuItemBase
    {...props}
    className="text-gray-800 text-sm md:text-base font-normal !flex items-center hover:!text-blue-500 !duration-75"
  />
);

const StyledMenu = styled(Menu)`
  display: flex;
  .ant-menu-item {
    padding: 0 10px;
    margin: 0 4px;
  }
  .ant-menu-item-selected {
    color: #2563eb !important;
    &:after {
      background-color: #2563eb;
    }
  }
`;

function NewNavMenuItem(props: any) {
  const { setUiConfig } = useUiConfig();
  const showNewNav = useBooleanFlag('new-manage-nav');
  if (!showNewNav) return null;

  return (
    // pass thru all ant props (it does weird stuff in menu)
    <MenuItem {...props} key="try-new-ui" onClick={() => setUiConfig({ useNewNav: true })}>
      Try our new UI
    </MenuItem>
  );
}

function InboxMenuItem(props: any) {
  const { uiConfig } = useUiConfig();
  const inboxReleaseFlag = getBooleanFlag('msg-inbox-release');
  const inboxOptInFlag = useBooleanFlag('msg-inbox-opt-in') === true;
  const showNewInbox = (uiConfig.useNewInbox === true && inboxOptInFlag) || inboxReleaseFlag;
  if (!showNewInbox) return null;
  return (
    <InboxOnboarding align="bottom">
      <DesktopMenuItem {...props}>
        <div className="flex items-center gap-2">
          <div>Inbox</div>
        </div>
      </DesktopMenuItem>
    </InboxOnboarding>
  );
}

interface TopNavProps {
  login: AccountLogin;
  selectedLocationIds: string[];
  route: string;
  showTitle: boolean;
  getWaitTime(locationId: string): void;
  clearDemoData(locationId: string): Promise<void>;
  resetDemoData(locationId: string): Promise<void>;
}

class TopNav extends PureComponent<TopNavProps> {
  demoActionsEnabled() {
    return (
      isSolvEmployeeAccount(this.props.login) &&
      this.props.selectedLocationIds.length === 1 &&
      this.props.selectedLocationIds.some((selectedId) => {
        const selectedLoc = this.props.login.account.locations.find(
          (accLoc) => accLoc.location_id === selectedId
        );
        return selectedLoc && isTestLocation(selectedLoc);
      })
    );
  }

  getAccountDropDownMenu = () => {
    const demoActionsAreEnabled = this.demoActionsEnabled();
    return (
      <Menu onClick={this.handleMenuClick}>
        {demoActionsAreEnabled && (
          <DesktopMenuItem key="clearDemoData">Clear Demo Data</DesktopMenuItem>
        )}
        {demoActionsAreEnabled && (
          <DesktopMenuItem key="resetDemoData">Reset Demo Data</DesktopMenuItem>
        )}
        {this.props.route !== 'hello' &&
          shouldShowFeature(this.props.login, FeatureName.QUEUE_TAB) && (
            <ResponsiveMenuItem key="queue">Queue</ResponsiveMenuItem>
          )}
        {this.props.route !== 'hello' &&
          shouldShowFeature(this.props.login, FeatureName.REPORTS_LIVE_DASHBOARD_TAB) && (
            <ResponsiveMenuItem key="reports">Reports</ResponsiveMenuItem>
          )}

        <NewNavMenuItem />
        <MenuItem key="logout">Log out</MenuItem>
      </Menu>
    );
  };

  getPopupContainerForAccountDropDown = () =>
    document.getElementById('top-nav-account-drop-down-container');

  trackTopNavClick = (menuItem: string) => {
    let trackingEvent;

    switch (menuItem) {
      case 'call-center':
        trackingEvent = TOP_NAV_SELECT_CALL_CENTER;
        break;
      case 'reports':
        trackingEvent = TOP_NAV_SELECT_REPORTS;
        break;
      case 'users':
        trackingEvent = TOP_NAV_SELECT_USERS;
        break;
      case 'settings':
        trackingEvent = TOP_NAV_SELECT_SETTINGS;
        break;
      case 'payments':
        trackingEvent = TOP_NAV_SELECT_PAYMENTS;
        break;
      case 'reviews-moderation':
        trackingEvent = TOP_NAV_SELECT_REVIEWS_MODERATION;
        break;
      case 'logout':
        trackingEvent = TOP_NAV_SELECT_LOG_OUT;
        break;
      case 'queue':
        trackingEvent = TOP_NAV_SELECT_QUEUE;
        break;
      case 'live-stats':
        trackingEvent = TOP_NAV_SELECT_RESPONSIVE_REPORTS_LIVE_STATS;
        break;
      case 'reviews':
        trackingEvent = TOP_NAV_SELECT_REVIEWS;
        break;
      case 'messaging':
        trackingEvent = TOP_NAV_SELECT_MESSAGING;
        break;
      case 'inbox':
        trackingEvent = TOP_NAV_SELECT_INBOX;
        break;
      default:
        break;
    }

    if (trackingEvent) {
      analyticsTrackEvent(trackingEvent, getTrackingProperties({ login: this.props.login }));
    }
  };

  handleMenuClick = ({ key }: { key: string }) => {
    console.log('handleMenuClick', key);
    this.trackTopNavClick(key);
    switch (key) {
      case 'queue': {
        history.push(getQueueUrlPath([]));
        break;
      }
      case 'reviews': {
        window.location.replace(RemixRoutes.reviews);
        break;
      }
      case 'messaging': {
        window.location.replace(RemixRoutes.messaging);
        break;
      }
      case 'payments':
        window.location.replace(RemixRoutes.payments);
        break;
      case 'inbox':
        window.location.replace(RemixRoutes.inbox);
        break;
      case 'connect':
      case 'call-center':
      case 'hello':
      case 'patients':
      case 'payments':
      case 'reviews-moderation':
      case 'platform-usage-alerts':
        history.push(`/${key}`);
        break;
      case 'reports':
        history.push(getTelemedOnlyReportsRedirect(this.props.login, key));
        break;
      case 'live-stats':
        history.push(`/reports/${key}`);
        break;
      case 'settings':
        history.push(`/settings`);
        break;
      case 'logout':
        history.push('/log-out');
        break;
      case 'clearDemoData':
        notification.info({
          duration: 0,
          message: (
            <div>
              Clearing demo data, hold plz....
              <br />
              This page will refresh automatically.
            </div>
          ),
        });

        if (this.props.selectedLocationIds[0]) {
          this.props.clearDemoData(this.props.selectedLocationIds[0]);
        }

        break;
      case 'resetDemoData':
        removeCookie(LAST_REVIEW_SEEN_COOKIE_NAME);
        notification.info({
          duration: 0,
          message: (
            <div>
              Resetting demo data, hold plz....
              <br />
              This page will refresh automatically.
            </div>
          ),
        });
        if (this.props.selectedLocationIds[0]) {
          this.props.resetDemoData(this.props.selectedLocationIds[0]);
        }

        break;
      default:
        break;
    }
  };

  _showReputationManagement = getBooleanFlag('show-reputation-management');

  render = () => (
    <div
      className={clsx(
        'top-nav h-[72px] border-b border-gray-200 pl-4 pr-4 flex flex-nowrap',
        'justify-between items-stretch overflow-x-auto overflow-y-hidden'
      )}
    >
      <StyledMenu
        mode="horizontal"
        onClick={this.handleMenuClick}
        selectedKeys={[this.props.route]}
      >
        <MenuItem key="hello" className="!flex items-center">
          <img
            width={210}
            height={77}
            alt="location logo"
            className="w-[70px] h-auto"
            src="/images/logo/dark_with_pink_dot.png"
          />
        </MenuItem>
        {this.props.route !== 'hello' &&
          shouldShowFeature(this.props.login, FeatureName.QUEUE_TAB) && (
            <DesktopMenuItem key="queue">Queue</DesktopMenuItem>
          )}
        {this.props.route !== 'hello' &&
          shouldShowFeature(this.props.login, FeatureName.QUEUE_TAB) && (
            <InboxMenuItem key="inbox" />
          )}
        {this.props.route !== 'hello' &&
          shouldShowFeature(this.props.login, FeatureName.CONNECT_TAB) && (
            <DesktopMenuItem key="connect">Connect</DesktopMenuItem>
          )}
        {this.props.route !== 'hello' &&
          shouldShowFeature(this.props.login, FeatureName.CALL_CENTER) && (
            <DesktopMenuItem key="call-center">Call Center</DesktopMenuItem>
          )}
        {this.props.route !== 'hello' &&
          shouldShowFeature(this.props.login, FeatureName.REPORTS_TAB) && (
            <DesktopMenuItem key="reports">Reports</DesktopMenuItem>
          )}

        {this.props.route !== 'hello' &&
          shouldShowFeature(this.props.login, FeatureName.SETTINGS_TAB) && (
            <DesktopMenuItem key="settings">Settings</DesktopMenuItem>
          )}
        {this.props.route !== 'hello' &&
          shouldShowFeature(this.props.login, FeatureName.PATIENTS_TAB) && (
            <DesktopMenuItem key="patients">Patients</DesktopMenuItem>
          )}
        {this.props.route !== 'hello' &&
          shouldShowFeature(this.props.login, FeatureName.PAYMENTS_TAB) && (
            <DesktopMenuItem key="payments">Payments</DesktopMenuItem>
          )}
        {this.props.route !== 'hello' &&
          shouldShowFeature(this.props.login, FeatureName.REVIEWS_MODERATION) && (
            <DesktopMenuItem key="reviews-moderation">Reviews Moderation</DesktopMenuItem>
          )}
        {this._showReputationManagement &&
          this.props.route !== 'hello' &&
          shouldShowFeature(this.props.login, FeatureName.REPORTS_REVIEWS_TAB) && (
            <DesktopMenuItem key="reviews">Reviews</DesktopMenuItem>
          )}
        {this.props.route !== 'hello' &&
          shouldShowFeature(this.props.login, FeatureName.MESSAGING_TAB) && (
            <DesktopMenuItem key="messaging">Messaging</DesktopMenuItem>
          )}
        {this.props.route !== 'hello' &&
          shouldShowFeature(this.props.login, FeatureName.PLATFORM_USAGE_ALERTS) && (
            <DesktopMenuItem key="platform-usage-alerts">Usage Alerts</DesktopMenuItem>
          )}
        {this.props.route !== 'hello' &&
          shouldShowFeature(this.props.login, FeatureName.HELP_CENTER) && (
            <DesktopMenuItem key="help-center">
              <a
                //  We have to override this again cause manage has aggressive global `a` styling
                className="!text-gray-800 !text-sm md:!text-base !font-normal !flex !items-center hover:!text-blue-500 !duration-75"
                href={HELP_CENTER_URL}
                target="_blank"
                rel="noreferrer"
              >
                Help Center
              </a>
            </DesktopMenuItem>
          )}
      </StyledMenu>

      <div id="top-nav-account-drop-down-container" />
      <Dropdown
        // @ts-ignore
        getPopupContainer={this.getPopupContainerForAccountDropDown}
        overlay={this.getAccountDropDownMenu()}
        placement="bottomRight"
        className="self-center"
      >
        <div>
          <div className="whitespace-nowrap text-gray-800 text-base items-center gap-1 hidden md:flex">
            {getAccountHolderName(this.props.login.account)}

            <Icon type="down" className="text-[12px]" />
          </div>
          <img src={'/images/queue/Vectorhamburger.png'} className="flex md:hidden" alt="menu" />
        </div>
      </Dropdown>
    </div>
  );
}

export default withViewport(TopNav);
