import { isDev } from '../config';
import { getClientConfig } from '../core/config/nextConfig';

export function remixUrl(url: string): string {
  const baseUrl = getClientConfig('NEXT_PUBLIC_REMIX_URL')
    ? getClientConfig('NEXT_PUBLIC_REMIX_URL')!
    : isDev()
    ? `http://localhost:3002`
    : 'https://manage.solvhealth.com';
  return urlJoin(baseUrl, url);
}

export const RemixRoutes = {
  connectBudgets: remixUrl('/r/connect/budgets'),
  reviews: remixUrl('/r/reviews'),
  messaging: remixUrl('/r/messaging'),
  groupEdit: (id: string) => remixUrl(`/r/group/edit/${id}`),
  payments: remixUrl('/r/payments'),
  schedulingSettings: (groupId?: string, locationId?: string) =>
    remixUrl(
      groupId
        ? `/r/scheduling/group/${groupId}${locationId ? `?viewForLocationId=${locationId}` : ''}`
        : '/r/scheduling'
    ),
  api: {
    auth: {
      clearCache: remixUrl('/r/api/auth/clear'),
    },
  },
  facesheetPayments: (id: string) => remixUrl(`/r/facesheet/payments/${id}`),
  facesheetPaymentsV3: (id: string) => remixUrl(`/r/facesheet/payments/${id}`),
  adminTools: remixUrl('/r/admin-tools'),
  emrTransfer: (bookingId: string) => remixUrl(`/r/facesheet/emr-transfer/${bookingId}`),
  integrationSettings: (locationId: string) =>
    remixUrl(`/r/integrations?location_id=${locationId}`),
  inbox: remixUrl('/r/inbox'),
  inboxEmbed: (conversationId: string) =>
    remixUrl(`/r/inbox-embed/conversations/${conversationId}`),
} as const;

export function urlJoin(...paths: string[]) {
  const precedingSlash = /^\/+/;
  const trailingSlash = /\/+$/;
  if (!paths || !paths.length) return '';

  return paths
    .map((path, index) => {
      if (!path) return '';
      if (index === 0) {
        return path.replace(trailingSlash, ''); // honor initial preceding slash if present
      }
      return path.replace(precedingSlash, '').replace(trailingSlash, '');
    })
    .filter((path) => path.length > 0)
    .join('/');
}
