import pick from 'lodash/pick';
import { QueryClient, useMutation, useQuery } from 'react-query';
import { DapiSingleResult } from '../core/dapi/response';
import { dapiClient } from '../core/http/clients';
import { HttpError } from '../core/http/http';
import { camelToSnake, queryStringFromObject } from '../core/util/string';
import { CommToggleName } from '../components/Settings/NewSettings/PatientCommunications/types';

export type UseFetchGeneralConfigsParams<FieldName extends keyof GeneralConfigsCore> = {
  locationId?: string;
  groupId?: string;
  serviceId?: string;
  fields: readonly FieldName[]; // readonly to facilitate `as const` usage.
  inheritFromGroup?: boolean;
  queryOptions?: {
    // See https://www.codemzy.com/blog/react-query-cachetime-staletime
    cacheTime?: number;
    staleTime?: number;
    enabled?: boolean;
  };
};

export async function queryGeneralConfigs<FieldName extends keyof GeneralConfigsCore>(
  params: Omit<UseFetchGeneralConfigsParams<FieldName>, 'queryOptions'>,
  options?: { signal?: AbortSignal }
) {
  // At least one of the entity IDs is required.
  let result;
  if (!(params.groupId || params.locationId || params.serviceId)) {
    const emptyResult: FetchGeneralConfigsPayloadStrict<FieldName> = {
      groupId: null,
      locationId: null,
      serviceId: null,
      values: {} as GeneralConfigsStrict,
    };
    console.debug('useQueryGeneralConfigs(): missing entity ID');
    result = emptyResult;
  } else {
    const qs = queryStringFromObject(
      {
        config_names: params.fields.map(camelToSnake),
        location_id: params.locationId,
        group_id: params.groupId,
        service_id: params.serviceId,
        inherit_from_group: params.inheritFromGroup,
      },
      {
        excludeNullish: true,
      }
    );
    type Result = DapiSingleResult<FetchGeneralConfigsPayloadStrict<FieldName>>;
    const queryResult = await dapiClient.getJson<Result>(`/v1/config?${qs}`, {
      camelCase: true,
      signal: options?.signal,
    });
    result = queryResult.data;
  }

  /**
   * Convert from GeneralConfigsStrict (i.g. partial) to GeneralConfigs (i.e.
   * missing properties are present w/null) by using pick().
   */
  const finalResult: FetchGeneralConfigsPayload<FieldName> = {
    ...result,
    values: unPartialGeneralConfigs(result.values, params.fields),
  };

  return finalResult;
}

/**
 * General-purpose hook for fetching general config values. Missing keys in the DB
 * are populated in the final output of the hook with null values.
 */
export function useQueryGeneralConfigs<FieldName extends keyof GeneralConfigsCore>({
  queryOptions,
  ...params
}: UseFetchGeneralConfigsParams<FieldName>) {
  return useQuery(
    useQueryGeneralConfigs.queryKey(params),
    async ({ signal }) => {
      return await queryGeneralConfigs(params, { signal });
    },
    {
      ...queryOptions,
    }
  );
}

useQueryGeneralConfigs.queryKey = (params: UseFetchGeneralConfigsParams<any>) => {
  const key: [
    queryFamilyName: string,
    ids: Record<string, string | null>,
    fields: null | Record<string, true>
  ] = [
    'useQueryGeneralConfigs',
    {
      // We don't care about the ordering of the IDs, so we turn them into an object
      // so that react-query won't care about their ordering either.
      locationId: params.locationId ?? null,
      groupId: params.groupId ?? null,
      serviceId: params.serviceId ?? null,
    },
    null,
  ];

  if (Array.isArray(params.fields) && params.fields.length > 0) {
    // We don't care about the ordering of the fields, so we turn them into an object
    // so that react-query won't care about their ordering either.
    key[2] = Object.fromEntries(params.fields.map((fieldName) => [fieldName, true] as const));
  }

  return key;
};

useQueryGeneralConfigs.invalidateKey = async (
  queryClient: QueryClient,
  keyToInvalidate: ReturnType<typeof useQueryGeneralConfigs['queryKey']>
) => {
  const idsToInvalidate = keyToInvalidate[1];

  if (!(idsToInvalidate.locationId || idsToInvalidate.groupId || idsToInvalidate.serviceId)) {
    return;
  }

  const fieldsToInvalidate = !keyToInvalidate[2] ? null : Object.keys(keyToInvalidate[2]!);

  return queryClient.invalidateQueries({
    predicate({ queryKey }) {
      if (queryKey[0] !== 'useQueryGeneralConfigs' || queryKey.length !== 3) {
        // This query isn't the right kind of query.
        return false;
      }

      const [_, queryIds, queryFields] = queryKey as ReturnType<
        typeof useQueryGeneralConfigs['queryKey']
      >;

      if (
        !(
          (idsToInvalidate.locationId && idsToInvalidate.locationId === queryIds.locationId) ||
          (idsToInvalidate.groupId && idsToInvalidate.groupId === queryIds.groupId) ||
          (idsToInvalidate.serviceId && idsToInvalidate.serviceId === queryIds.serviceId)
        )
      ) {
        // The ids of this query do not overlap with the ids we're trying to invalidate.
        // Therefore, no need to invalidate this query.
        return false;
      }

      // At this point, we know that the ids of the current query overlap with those that we're looking for.

      if (!queryFields) {
        // The ids of this query match, but this query didn't specify any fields. That's probably not valid,
        // but it could be interpreted as fetching ALL config for the relevant ids. Best to invalidate it just
        // to be safe.
        return true;
      }

      if (!fieldsToInvalidate?.length) {
        // We didn't specify any fields to invalidate based upon, so at this point we should invalidate any
        // query whose ids match up.
        return true;
      }

      // At this point, we've established that the current query specified some fields in its key AND we're trying
      // to invalidate queries based upon which fields they specified, so let's see if those two sets fields overlap.
      for (let i = 0; i < fieldsToInvalidate.length; i++) {
        if (queryFields[fieldsToInvalidate[i]]) {
          return true;
        }
      }

      return false;
    },
  });
};

export async function postGeneralConfigs<FieldName extends keyof GeneralConfigsCore>(params: {
  id: string;
  entityType: 'location' | 'group' | 'service';
  values: Pick<PostGeneralConfigsPayload, FieldName>;
}): Promise<FetchGeneralConfigsPayload<FieldName>> {
  // To avoid dev mistakes, we treat all present-but-undefined properties as missing, explicitly
  // omitting them from the payload. Only values that are present AND null are POSTed as a deletion
  // of that field. Values that are neither null nor undefined are included in the payload as normal.
  const valuesWithoutUndefined = Object.fromEntries(
    Object.entries(params.values).filter(([configKey, configValue]) => configValue !== undefined)
  ) as typeof params['values'];
  type Result = DapiSingleResult<FetchGeneralConfigsPayloadStrict<FieldName>>;
  const result = await dapiClient.postJson<Result>(
    `/v1/config/${params.entityType}/${params.id}`,
    {
      values: valuesWithoutUndefined,
    },
    {
      camelCase: true,
    }
  );

  return {
    ...result.data,
    values: unPartialGeneralConfigs(result.data.values, Object.keys(params.values) as FieldName[]),
  };
}

/**
 * General purpose hook for updating general configs.
 * @param entityType whether you are updating location/group/integration-service general configs.
 */
export function usePostGeneralConfigMutation(entityType: 'location' | 'group' | 'service') {
  return useMutation<
    void,
    HttpError,
    {
      id: string;
      values: PostGeneralConfigsPayload;
    }
  >(['post-general-config'], async ({ id, values }) => {
    await postGeneralConfigs({
      entityType: entityType,
      id,
      values,
    });
  });
}
export interface GeneralConfigsCommSetting {
  disabledBookingOrigins: string[] | null;
}

/**
 * Partial record of known keys & values for general configs. This should ideally just be the "real"
 * type of each field. When you're referencing a general config field in manage for the first time,
 * simply add its type here & add an entry for it in emptyGeneralConfig below. If a particular config
 * isn't being used or referenced in the manage codebase, it doesn't need to be included here.
 */
interface GeneralConfigsCore {
  chatmeterId: number;
  chatmeterBaseUrl: string;
  hideInsuranceFlowSolvAttributedBooking: boolean;
  palette: string;
  hasLongerWaitTimes: boolean;
  isNextDayAppointmentsDisabled: boolean;
  enableInsuranceOcrBookingWidget: boolean;
  enableInsuranceOcrPaperwork: boolean;
  useBookingWidgetV2: boolean;
  usePaperworkV2: boolean;
  useKioskV3: boolean;
  disableExportToAppleMaps: boolean;
  isCheckinAllowedOutsideBusinessHours: boolean;
  checkinOutsideBusinessHoursInterval: number;
  [CommToggleName.APPOINTMENT_CHECK_IN]: GeneralConfigsCommSetting;
  [CommToggleName.APPOINTMENT_REMINDER]: GeneralConfigsCommSetting;
  [CommToggleName.APPOINTMENT_REMINDER_PROMPT]: GeneralConfigsCommSetting;
  [CommToggleName.BOOKING_CANCELLATION_CONFIRMATION]: GeneralConfigsCommSetting;
  [CommToggleName.BOOKING_CANCELLATION_CONFIRMATION_EMAIL]: GeneralConfigsCommSetting;
  [CommToggleName.BOOKING_CONFIRMATION]: GeneralConfigsCommSetting;
  [CommToggleName.BOOKING_CONFIRMATION_EMAIL]: GeneralConfigsCommSetting;
  [CommToggleName.DISCHARGE_GOOGLE_REVIEW_EMAIL]: GeneralConfigsCommSetting;
  [CommToggleName.PROMPT_FOR_REVIEW_SMS_ONE]: GeneralConfigsCommSetting;
  [CommToggleName.PROMPT_FOR_REVIEW_SMS_TWO]: GeneralConfigsCommSetting;
  [CommToggleName.KIOSK_SIGN_IN_CONFIRMATION]: GeneralConfigsCommSetting;
  [CommToggleName.ONBOARDING_EMAIL]: GeneralConfigsCommSetting;
  [CommToggleName.PAPERWORK_LINK]: GeneralConfigsCommSetting;
  [CommToggleName.POST_VISIT_SUMMARY_SMS]: GeneralConfigsCommSetting;
  [CommToggleName.WRAP_UP_EMAIL]: GeneralConfigsCommSetting;
  [CommToggleName.REVIEW_RESPONSE]: GeneralConfigsCommSetting;
  isAddressValidationEnabled: boolean;
  isDataValidatorEnabled: boolean;
  autoNoShow: { active: boolean; timeThresholdInHours: number };
  autoDischarge: { active: boolean; timeThresholdInHours: number };
  solvPayV2IntelligentReminders: boolean;
  solvPayV2CollectionsWarning: boolean;
  solvPayV2AutoCollect: boolean;
  solvPayV2AllowAutoCollectOverride: boolean;
  solvPayV2InvoiceEstimates: boolean;
  solvPayTerminal: boolean;
  solvPayPaymentPlans: boolean;
  solvPayPaymentPlansMinimumAmount: number | null;
  solvPayPaymentPlansMinimumInstallment: number | null;
  solvPayPaymentPlansMaximumDuration: number | null;
  solvPayPaymentPlansWhenToOfferToConsumers: 'never' | 'automatically' | 'always';
  rteV2: boolean;
  enableOnlineWaitlist: { solvAttributed: boolean; bookingWidget: boolean };
  enterprisePartner: boolean;
  externalBookingCustomMessage: { en: string; es: string };
  repManagementReplySolvReviewsEnabled: boolean;
  repManagementReplyGoogleReviewsEnabled: boolean;
  repManagementVisitInsightsEnabled: boolean;
  repManagementLookerReportsEnabled: boolean;
  repManagementExportReviewEnabled: boolean;
  repManagementAlwaysReviewOnGoogleEnabled: boolean;
  dynamicConsentFormInterpreterServicesEnabled: boolean;
  dynamicConsentFormPhiReleaseEnabled: boolean;
  variableVisitsSlottingEnabled: boolean;
  allowVisitTypeChange: boolean;
  whatConvertsId: string;
  validateRequiredFieldsEnabled: boolean;
  useEpicPatientSexOptions: boolean;
}

export type PaymentPlansGeneralConfigsFields = Pick<
  GeneralConfigsCore,
  | 'solvPayPaymentPlans'
  | 'solvPayPaymentPlansMinimumAmount'
  | 'solvPayPaymentPlansMinimumInstallment'
  | 'solvPayPaymentPlansMaximumDuration'
  | 'solvPayPaymentPlansWhenToOfferToConsumers'
>;

/**
 * A strict representation of what dapi delivers in response to fetch calls for
 * general configs. Everything is optional, because of a location might be missing any particular field.
 */
export type GeneralConfigsStrict = Partial<GeneralConfigsCore>;

/**
 * It's often more convenient to treat MISSING keys as instead being present on the object but simply
 * having a null value, so this type supports that use case. Instead of being optional, all properties
 * are required, but each might be null.
 */
export type GeneralConfigs = {
  [ConfigKey in keyof GeneralConfigsCore]: GeneralConfigsCore[ConfigKey] | null;
};

/**
 * A utility object for scenarios where you are interacting with an indefinite subset of general configs,
 * but don't want to deal with Partial<>'s all over the place. react-hook-form in particular is known to
 * be frustrating to work with when given missing or undefined properties.
 *
 * You can think of it as a utility for bridging the gap between GeneralConfigsStrict and GeneralConfigs.
 *
 * In terms of structure, this should have all the same keys as GeneralConfigsCore, but all its values
 * should be `null`.
 */
export const emptyGeneralConfigs: { readonly [Key in keyof GeneralConfigsCore]: null } = {
  repManagementLookerReportsEnabled: null,
  repManagementReplyGoogleReviewsEnabled: null,
  repManagementExportReviewEnabled: null,
  repManagementReplySolvReviewsEnabled: null,
  repManagementVisitInsightsEnabled: null,
  repManagementAlwaysReviewOnGoogleEnabled: null,
  enableInsuranceOcrBookingWidget: null,
  enableInsuranceOcrPaperwork: null,
  chatmeterId: null,
  chatmeterBaseUrl: null,
  palette: null,
  hideInsuranceFlowSolvAttributedBooking: null,
  hasLongerWaitTimes: null,
  isNextDayAppointmentsDisabled: null,
  useBookingWidgetV2: null,
  usePaperworkV2: null,
  useKioskV3: null,
  disableExportToAppleMaps: null,
  isCheckinAllowedOutsideBusinessHours: null,
  checkinOutsideBusinessHoursInterval: null,
  [CommToggleName.APPOINTMENT_CHECK_IN]: null,
  [CommToggleName.APPOINTMENT_REMINDER]: null,
  [CommToggleName.APPOINTMENT_REMINDER_PROMPT]: null,
  [CommToggleName.BOOKING_CANCELLATION_CONFIRMATION]: null,
  [CommToggleName.BOOKING_CONFIRMATION]: null,
  [CommToggleName.PROMPT_FOR_REVIEW_SMS_ONE]: null,
  [CommToggleName.PROMPT_FOR_REVIEW_SMS_TWO]: null,
  [CommToggleName.KIOSK_SIGN_IN_CONFIRMATION]: null,
  [CommToggleName.PAPERWORK_LINK]: null,
  [CommToggleName.POST_VISIT_SUMMARY_SMS]: null,
  [CommToggleName.BOOKING_CANCELLATION_CONFIRMATION_EMAIL]: null,
  [CommToggleName.BOOKING_CONFIRMATION_EMAIL]: null,
  [CommToggleName.DISCHARGE_GOOGLE_REVIEW_EMAIL]: null,
  [CommToggleName.ONBOARDING_EMAIL]: null,
  [CommToggleName.WRAP_UP_EMAIL]: null,
  [CommToggleName.REVIEW_RESPONSE]: null,
  isAddressValidationEnabled: null,
  isDataValidatorEnabled: null,
  autoNoShow: null,
  autoDischarge: null,
  solvPayV2IntelligentReminders: null,
  solvPayV2CollectionsWarning: null,
  solvPayV2AutoCollect: null,
  solvPayV2AllowAutoCollectOverride: null,
  solvPayV2InvoiceEstimates: null,
  solvPayTerminal: null,
  solvPayPaymentPlans: null,
  solvPayPaymentPlansMinimumAmount: null,
  solvPayPaymentPlansMinimumInstallment: null,
  solvPayPaymentPlansMaximumDuration: null,
  solvPayPaymentPlansWhenToOfferToConsumers: null,
  rteV2: null,
  enableOnlineWaitlist: null,
  enterprisePartner: null,
  externalBookingCustomMessage: null,
  dynamicConsentFormInterpreterServicesEnabled: null,
  dynamicConsentFormPhiReleaseEnabled: null,
  variableVisitsSlottingEnabled: null,
  allowVisitTypeChange: null,
  whatConvertsId: null,
  validateRequiredFieldsEnabled: null,
  useEpicPatientSexOptions: null,
};

export function unPartialGeneralConfigs<FieldName extends keyof GeneralConfigsCore>(
  values: GeneralConfigsStrict | undefined | null,
  fieldNames: readonly FieldName[]
): Pick<GeneralConfigs, FieldName> {
  return {
    ...pick(emptyGeneralConfigs, fieldNames),
    ...pick(values ?? {}, fieldNames),
  };
}

/** A strict & faithful representation of what you might get back from dapi when fetching general configs. */
export interface FetchGeneralConfigsPayloadStrict<FieldName extends keyof GeneralConfigsCore> {
  locationId: string | null;
  groupId: string | null;
  serviceId: string | null;
  values: Pick<GeneralConfigsStrict, FieldName>;
}

/**
 * Represents what you might get back from dapi when fetching general configs, but makes the assumption
 * that missing values are instead represented as null properties.
 */
export type FetchGeneralConfigsPayload<FieldName extends keyof GeneralConfigsCore> = Omit<
  FetchGeneralConfigsPayloadStrict<FieldName>,
  'values'
> & {
  values: Pick<GeneralConfigs, FieldName>;
};

/**
 * A representation of a viable payload to POST to the backend updating general_configs values.
 * It's partial because you can update a subset of fields at any time, and each property may be null
 * because that's how you delete a given field.
 */
export type PostGeneralConfigsPayload = Partial<GeneralConfigs>;
